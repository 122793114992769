import { 
  IonRouterOutlet, 
  IonTabs, 
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonText
} from '@ionic/react';

import { Redirect, Route } from 'react-router-dom';
import React from 'react';

import routes from '../config/routes';

import Dashboard from './Dashboard';
import Search from './Search';
import Article from './Article';
import Page from './Page';
import Settings from './Settings';

import iconSearch from '../assets/icons/search.svg';
import iconDashboard from '../assets/icons/dashboard.svg';
import iconSettings from '../assets/icons/profile.svg';

const Logged = () => {

  return (

    <IonTabs>
      
      <IonRouterOutlet>
        <Route exact path={routes.dashboard} component={Dashboard} />
        <Route exact path={routes.search} component={Search} />
        <Route path={routes.article} component={Article} />
        <Route exact path={routes.settings} component={Settings} />
        <Route exact path={routes.logged} render={() => <Redirect to={routes.dashboard} />} />
        <Route path={routes.page} component={Page} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className="cbo-tabbar">
        <IonTabButton tab="tab-search" href={ routes.search } className="tabbar-button">
          <IonIcon className="button-icon" src={ iconSearch }></IonIcon>
          <IonText className="button-label">Explorer</IonText>
        </IonTabButton>
        <IonTabButton tab="tab-dashboard" href={ routes.dashboard } className="tabbar-button">
          <IonIcon className="button-icon" src={ iconDashboard }></IonIcon>
          <IonText className="button-label">Aujourd'hui</IonText>
        </IonTabButton>
        <IonTabButton tab="tab-settings" href={ routes.settings } className="tabbar-button">
          <IonIcon className="button-icon" src={ iconSettings }></IonIcon>
          <IonText className="button-label">Profil</IonText>
        </IonTabButton>
      </IonTabBar>

    </IonTabs>
  );
};

export default Logged;
