const endpoints = {
	
	'server': process.env.REACT_APP_SERVER,
	'path': '/api',
	'user':{
		'subscribe':'/users',
		'login':'/login_check',
		'getDetails':'/users/{id}',
		'update':'/users/{id}',
		'forgotpwd':'/users/forgotpwd?email={email}'
	},
	'articles':{
		'getList':'/articles',
		'getDetails':'/articles/{id}',
		'validate':'/article/{id}/validate',
		'ignore':'/article/{id}/ignore'
	},
	'pages':{
		'getList':'/pages',
		'getDetails':'/pages/{id}'
	},
	'userArticles':{
		'getList':'/user_articles',
		'getPushed':'/user_articles/{id}'
	},
	'categories':{
		'getList':'/categories',
		'getDetails':'/categories/{id}'
	},
	'popins':{
		'getList':'/popins'
	},

	get( group, action, params ){
	    let r = this[ group ][ action ]
	    for (let [key, value] of Object.entries( params )) {
	      r = r.replace( '{'+key+'}', value )
	    }
	    return r
	}
};

export default endpoints;