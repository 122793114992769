import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export const setToken = async ( token ) => {
  await Storage.set({
    key: 'token',
    value: token
  })
}

export const getToken = async () => {
  const token = await Storage.get({ key: 'token' })
  return token
}

export const deleteToken = async () => {
  await Storage.remove({ 
    key: 'token' 
  })
}