import { 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonButton, 
  IonPage,
  IonIcon
} from '@ionic/react';

import React from 'react';
import { chevronBack } from 'ionicons/icons';
import UserForm from '../components/UserForm';
import routes from '../config/routes';

const Subscribe = (props) => {

  return (
    <IonPage className="cbo-page">

   	  <IonHeader className="page-header ion-no-border ion-padding">
         <IonToolbar className="header-toolbar" color="primary">
          <IonButtons slot="start">
            <IonButton routerLink={ routes.home } routerDirection="back">
              <IonIcon icon={ chevronBack }></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="page-content">

        <h1 className="content-title">
          Je crée mon compte
        </h1>

        <div className="content-main ion-padding">
          <UserForm mode="CREATE" history={props.history} />
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Subscribe;
