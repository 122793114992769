import { IonList, IonItem, IonLabel, IonInput, IonButton, IonCheckbox, IonDatetime, IonText } from '@ionic/react';
import React, {useState, useContext} from 'react';
import axios from 'axios';
import endpoints from '../config/endpoints';
import routes from '../config/routes';
import { AppContext } from '../state';
import './UserForm.scss';

const UserForm = (props) => {      

  const { state, dispatch } = useContext(AppContext);

  let oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const maxDate = new Date(oneYearFromNow).toISOString();

  const valFirstname = (props.mode === 'CREATE') ? '' : state.user.details.prenom;
  const valLastname = (props.mode === 'CREATE') ? '' : state.user.details.nom;
  const valEmail = (props.mode === 'CREATE') ? '' : state.user.details.email;
  const valPassword = (props.mode === 'CREATE') ? '' : state.user.details.password;
  const valBirthdate = (props.mode === 'CREATE') ? '2020-01-01' : state.user.details.dateNaissanceBebe;
  const valCgu = (props.mode === 'CREATE') ? false : true;
  const valCommercial = (props.mode === 'CREATE') ? false : state.user.details.optin;

  const buttonLabel = (props.mode === 'CREATE') ? "Je m'inscris" : "Mettre à jour";

  const [firstname, setFirstname] = useState( valFirstname );
  const [lastname, setLastname] = useState( valLastname );
  const [email, setEmail] = useState( valEmail );
  const [password, setPassword] = useState( valPassword );
  const [validPassword, setValidPassword] = useState( null );
  const [birthdate, setBirthdate] = useState( valBirthdate );
  const [cgu, setCgu] = useState( valCgu );
  const [commercial, setCommercial] = useState( valCommercial );

  const FormCheck = (e) => {

    e.preventDefault();

    if(firstname !== '' && lastname !== '' && email !== '' && password !== '' && validPassword === true && birthdate !== '' && cgu === true){

      // User create
      if(props.mode === 'CREATE'){

        axios.post( endpoints.user.subscribe, {
          prenom:firstname,
          nom:lastname,
          email:email,
          password:password,
          dateNaissanceBebe:birthdate,
          optin:commercial
        })
        .then(({ data }) => {
          
          if(data)
            props.history.push( routes.login );
        })
      }

      // User edit
      else if(props.mode === 'UPDATE'){

        axios.put( endpoints.get('user', 'update', {id:state.user.details.id}), {
          prenom:firstname,
          nom:lastname,
          email:email,
          password:password,
          dateNaissanceBebe:birthdate,
          optin:commercial
        })
        .then(({ data }) => {

          // Toast
          dispatch({type:'setToast', data:{
            show:true,
            message:'Votre profil a bien été modifié',
            color:'success'
          }})

          // User details reload
          axios.get( endpoints.user.getDetails )
          .then( (response) => {
            dispatch({type:'setUserDetails', data:response.data});
          })
        })
      }
    }
  };  

  function checkPassword(pwd){
    setPassword(pwd)
    if(pwd.length < 6)
      setValidPassword( false )
    else
      setValidPassword( true ) 
  }

  return(

    <form onSubmit={e => FormCheck(e)}>

      <IonList lines="full">

        <IonItem>
          <IonLabel position="stacked">Prénom</IonLabel>
          <IonInput type="text" value={firstname} required onIonChange={e =>  setFirstname(e.target.value)} />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Nom</IonLabel>
          <IonInput type="text" value={lastname} required onIonChange={e =>  setLastname(e.target.value)} />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Email</IonLabel>
          <IonInput type="email" value={email} required onIonChange={e =>  setEmail(e.target.value)} />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Mot de passe</IonLabel>
          <IonInput type="password" value={password} required onIonChange={e =>  checkPassword(e.target.value || '')} />
          {validPassword === false 
            ? <IonText className="cbo-hint hint--invalid">Le mot de passe est trop court (au moins 6 caractères)</IonText>
            : <IonText className="cbo-hint">Le mot de passe doit contenir au moins 6 caractères</IonText>
          }
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Naissance de bébé</IonLabel>
          <IonDatetime 
            required
            mode="md"
            displayFormat="DD MM YYYY"
            placeholder="Choisir une date" 
            value={birthdate} 
            max={maxDate}
            onIonChange={e => setBirthdate(e.detail.value)}
          ></IonDatetime>
        </IonItem>

        {props.mode === 'CREATE' && 
        <IonItem>
          <IonCheckbox slot="start" required onIonChange={e => setCgu(e.detail.checked)} checked={cgu} />
          <IonLabel>J’accepte les CGU de l’application.</IonLabel>
        </IonItem>
        }
        
        <IonItem>
          <IonCheckbox slot="start" onIonChange={e => setCommercial(e.detail.checked)} checked={commercial} />
          <IonLabel>J’accepte de recevoir par email des offres promotionnelles de la part de la société Nactalia.</IonLabel>
        </IonItem>

      </IonList>

      <IonButton type="submit"
        expand="full" 
        shape="round"
        size="large"
        className="cbo-button ion-margin"
        color="primary"
       >
        {buttonLabel}
      </IonButton>

    </form>
  );
};

export default UserForm;