import { 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonPage, 
  IonList, 
  IonItem, 
  IonLabel, 
  IonInput, 
  IonButton,
  IonRouterLink,
  IonIcon
} from '@ionic/react';

import React, {useState} from 'react';
import { chevronBack } from 'ionicons/icons';
import axios from 'axios';
import endpoints from '../config/endpoints';
import routes from '../config/routes';
import {setToken} from '../config/storage';
import { useApi } from '../state/Actions';

const Login = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useApi();

  const LoginCheck = (e) => {

    e.preventDefault();
    
    axios.post( endpoints.user.login, {
      email:email,
      password:password
    })
    .then(({ data }) => {
      setToken( data.token ).then(() => {
        login( data.token );
      })      
    });
  };

  return (
    <IonPage className="cbo-page">

      <IonHeader className="page-header ion-no-border ion-padding">
         <IonToolbar className="header-toolbar" color="primary">
          <IonButtons slot="start">
            <IonButton routerLink={ routes.home } routerDirection="back">
              <IonIcon icon={ chevronBack }></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="page-content">

        <h1 className="content-title">
          Je me connecte
        </h1>

        <div className="content-main ion-padding">
          <form onSubmit={e => LoginCheck(e)}>

            <IonList lines="full">

              <IonItem>
                <IonLabel position="stacked">Identifiant</IonLabel>
                <IonInput type="text" name="email" required onIonChange={e =>  setEmail(e.target.value)} />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Mot de passe</IonLabel>
                <IonInput type="password" name="password" required onIonChange={e =>  setPassword(e.target.value)} />
              </IonItem>

            </IonList>

            <div className="ion-padding ion-text-center">
              <IonRouterLink routerLink={ routes.password }>
                Mot de passe oublié ?
              </IonRouterLink>
            </div>

            <IonButton type="submit"
              expand="full" 
              shape="round"
              size="large"
              className="cbo-button ion-margin"
              color="primary"
             >
              Se connecter
            </IonButton>

          </form>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Login;
