import { IonText, IonSpinner } from '@ionic/react';
import React from 'react';
import './Loading.scss';

const Loading = (props) => {

	return(
		
        <IonText className="cbo-loading">
          	<IonText className="loading-text">Chargement</IonText>
          	<IonSpinner className="loading-spinner" name="crescent" />
        </IonText>

	);
};

export default Loading;
