import { 
  IonApp, 
  IonRouterOutlet, 
  IonToast,
  IonMenu,
  IonMenuToggle,
  IonContent,
  IonFooter,
  IonList,
  IonItem,
  IonText,
  IonImg,
  IonModal,
  IonButton,
  IonLoading
} from '@ionic/react';

import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';

import endpoints from './config/endpoints'
import routes from './config/routes';
import { getToken } from './config/storage';

import axios from 'axios';
import { AppContext } from './state';
import { useApi } from './state/Actions';

import Home from './pages/Home';
import Login from './pages/Login';
import Password from './pages/Password';
import Subscribe from './pages/Subscribe';
import Logged from './pages/Logged';
import NotConnected from './pages/NotConnected';

import MumLevel from './components/MumLevel';
import Loading from './components/Loading';

import logoSymbol from './assets/img/logo-symbol.svg';
import logoSymbolGreen from './assets/img/logo-symbol-green.svg';
import logoNactalia from './assets/img/logo-nactalia.png';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* App styles */
import './theme/app.scss';

const App = (props) => {

  // Global state
  const { state, dispatch } = useContext(AppContext);
  const { loadData, login, logout } = useApi();

  /* eslint-disable */

  // Axios
  useEffect(() => {

    function hideLoader(){
      setTimeout( () => { dispatch({ type:'hideLoader' }); }, 1000);
    }

    // Axios basic configuration
    axios.defaults.baseURL = endpoints.server + endpoints.path;
    axios.defaults.headers.common['Accept'] = 'application/json';

    // Axios request interceptor
    axios.interceptors.request.use(function (config) {
      dispatch({ type:'showLoader' });
      return config;
    }, function (error) {
      hideLoader();
      return Promise.reject(error);
    });

    // Axios response interceptor
    axios.interceptors.response.use(
      
      function (response) {

        if(!state.user.connected)
          dispatch({ type:'setUserConnected', data:true });

       hideLoader();
        return response;
      }, 

      function (error) {

        if(error.response && error.response.data){

          if(!state.user.connected)
            dispatch({ type:'setUserConnected', data:true });

          if(error.response.data.message || error.response.data.title || error.response.data.detail){
            dispatch({ type:'setToast', data:{
              show:true,
              message: error.response.data.detail || error.response.data.message || error.response.data.title,
              color:'danger'
            }})
          }

          // Empty state and logout if unauthorized
          if(error.response.data.code === 401){
            setTimeout( () => { 
              logout();
            }, 2500);
          }

        }

        else{

          if(state.user.connected !== false)
            dispatch({ type:'setUserConnected', data:false });

          dispatch({ type:'setToast', data:{
            show:true,
            message:'Connexion introuvable',
            color:'danger'
          }});
        }

        hideLoader();
        return Promise.reject(error);
      }
    );
  }, []);


  // Token
  useEffect(() => {
    if(state.user.token === false && state.user.connected !== false){
      getToken().then( (token) => {
        
        if(token.value){
          login(token.value);
        }
        else{
          dispatch({type:'setUserToken', data:null});
        }

      })
    }
  }, []);

  /* eslint-enable */


  // Load data
  useEffect(() => {
    if( state.user.token !== null && state.user.token !== false && state.user.connected === true ){
      loadData();
    }
  }, [state.user.token, state.user.connected, loadData]);

  

  function disableUserModalValidated(){
    dispatch({ type:'setUserModalValidated', data:false });
  }

  function disableUserModalNewLevel(){
    dispatch({ type:'setUserModalNewLevel', data:false });
  }

  if( state.user.token !== false || state.user.connected === false ){
    return(
      <IonApp>
        <IonReactRouter history={props.history}>

          { state.user.token !== null &&
          <IonMenu 
            content-id="my-content"
            type="overlay"
            className="cbo-menu"
          >
            <IonContent className="menu-content">
              <div className="content-header">
                <IonImg src={ logoSymbol } alt="Elever bébé naturellement" />
              </div>
              <IonMenuToggle>
                <IonList 
                  lines="none"
                  className="content-list"
                >
                { state.pages.list.map(( page, index ) => {

                  const key = 'menu_page_'+index;
                  const route = routes.get('page', {id:page.id});

                  return(
                    <IonItem 
                      key={ key } 
                      routerLink={ route }
                      detailIcon={ false }
                      className="list-el"
                    >
                      <IonText 
                        color="light"
                        className="el-inner"
                      >
                        { page.title }
                      </IonText>
                    </IonItem>
                  );

                })}
                </IonList>
              </IonMenuToggle>
            </IonContent>
            <IonFooter className="menu-footer">
              <a href="https://www.nactalia.fr/">
                <IonImg src={ logoNactalia } alt="Nactalia" />
              </a>
            </IonFooter>
          </IonMenu>
          }
       
          <IonContent id="my-content" forceOverscroll={false} scrollEvents={false}>
            <IonRouterOutlet>

              <Route exact path={routes.not_connected}
                render={props => {
                  if(state.user.connected === true){
                    if (state.user.token === null){
                      return <Redirect to={routes.home} />
                    } else {
                      return <Redirect to={routes.dashboard} />
                    }
                  }
                  else{
                    return <NotConnected {...props} />
                  }
                }}
              />

              <Route path={routes.home}
                render={props => {
                  if(state.user.connected === true){
                    if (state.user.token === null){
                      return <Home {...props} />
                    } else {
                      return <Redirect to={routes.dashboard} />
                    }
                  }
                  else{
                    return <Redirect to={routes.not_connected} />
                  }
                }}
              />

              <Route exact path="/" render={() => <Redirect to={routes.home} />} />

              <Route path={routes.login}
                render={props => {
                  if(state.user.connected === true){
                    if (state.user.token === null){
                      return <Login {...props} />
                    } else {
                      return <Redirect to={routes.dashboard} />
                    }
                  }
                  else{
                    return <Redirect to={routes.not_connected} />
                  }
                }}
              />

              <Route path={routes.password}
                render={props => {
                  if(state.user.connected === true){
                    if (state.user.token === null){
                      return <Password {...props} />
                    } else {
                      return <Redirect to={routes.dashboard} />
                    }
                  }
                  else{
                    return <Redirect to={routes.not_connected} />
                  }
                }}
              />

              <Route path={routes.subscribe}
                render={props => {
                  if(state.user.connected === true){
                    if (state.user.token === null){
                      return <Subscribe {...props} />
                    } else {
                      return <Redirect to={routes.dashboard} />
                    }
                  }
                  else{
                    return <Redirect to={routes.not_connected} />
                  }
                }}
              />

              <Route path={routes.logged}
                render={props => {
                  if(state.user.connected === true){
                    if (state.user.token !== null && state.user.token !== false){
                      return <Logged {...props} />
                    } else {
                      return <Redirect to={routes.login} />
                    }
                  }
                  else{
                    return <Redirect to={routes.not_connected} />
                  }
                }}
              />

            </IonRouterOutlet>
          </IonContent>

        </IonReactRouter>

        <IonModal 
          className="cbo-modal"
          isOpen={ state.user.modalValidated }
          showBackdrop
          ionModalWillDismiss={() => disableUserModalValidated()}
        >
          <div className="modal-inner">
            <div className="modal-title cbo-thirdtitle">
              <IonText color="primary">Un petit geste en plus !</IonText>
            </div>
            <div className="modal-content">
              c'est la planète et bébé qui vont être contents !
              <IonImg src={ logoSymbolGreen } alt="" />
            </div>
            <button className="modal-close" type="button" onClick={() => disableUserModalValidated()}></button>
          </div>
        </IonModal>

        { state.popins.selected.id &&
        <IonModal 
          className="cbo-levelmodal"
          isOpen={ state.user.modalNewLevel }
          ionModalWillDismiss={() => disableUserModalNewLevel()}
        >
          <IonContent className="levelmodal-inner">

              <div className="levelmodal-top">
                <div className="top-title cbo-thirdtitle">
                  <IonText color="light">
                    <strong>Félicitations,</strong><br />
                    vous passez<br />
                    au niveau supérieur
                  </IonText>
                </div>
                <div className="top-level">
                  <MumLevel reverseColors />
                </div>
              </div>

              { state.popins.selected.title !== '' && 
                state.popins.selected.text !== '' && 
                state.popins.selected.image !== '' && 
                state.popins.selected.downloadUrl !== '' &&
              <div className="levelmodal-offer">
                <IonText className="offer-title cbo-thirdtitle" color="primary">
                  { state.popins.selected.title }
                </IonText>
                <IonText className="offer-text">
                  { state.popins.selected.text }
                </IonText>
                <div className="offer-picture">
                  <IonImg src={ endpoints.server + state.popins.selected.image } alt="" />
                </div>
                <div className="offer-button">
                  <IonButton
                    shape="round"
                    size="large"
                    className="cbo-button ion-margin"
                    color="primary"
                    href={ state.popins.selected.downloadUrl }
                   >
                    Télécharger
                  </IonButton>
                  </div>
              </div>
              }

              { state.popins.selected.footerImage !== '' &&
                state.popins.selected.footerText !== '' &&
                state.popins.selected.buttonUrl !== '' && 
                state.popins.selected.buttonLabel !== '' &&
              <div className="levelmodal-branding">
                <div className="branding-picture">
                  <IonImg src={ endpoints.server + state.popins.selected.footerImage } alt="" />
                </div>
                <IonText className="branding-title cbo-thirdtitle" color="light">
                  { state.popins.selected.footerText }
                </IonText>
                <div className="branding-button">
                  <IonButton
                    shape="round"
                    size="large"
                    className="cbo-button ion-margin"
                    color="light"
                    download
                    href={ state.popins.selected.buttonUrl }
                   >
                    <IonText color="primary">{ state.popins.selected.buttonLabel }</IonText>
                  </IonButton>
                  </div>
              </div>
              }

              <button className="levelmodal-close" type="button" onClick={() => disableUserModalNewLevel()}></button>
            
          </IonContent>
        </IonModal>
        }

        <IonLoading
          isOpen={state.loader.show}
          message="Veuillez patienter..."
          spinner="crescent"
        />

        <IonToast
          isOpen={state.toast.show}
          onDidDismiss={() => dispatch({type:'resetToast'})}
          message={state.toast.message}
          duration={3000}
          color={state.toast.color}
          mode="ios"
        />

      </IonApp>
    )
  }

  else

    return(
      <IonApp>
        <IonContent>
          <Loading />
          {state.user.connected}
        </IonContent>
      </IonApp>
    )
}

export default App;
