import { 
  IonContent, 
  IonPage, 
  IonHeader, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardContent, 
  IonCardTitle, 
  IonSlides, 
  IonSlide, 
  IonText,
  IonImg,
  IonMenuButton,
  useIonViewDidLeave
} from '@ionic/react';

import React, { useContext, useRef } from 'react';

import MumLevel from '../components/MumLevel';
import ArticleCard from '../components/ArticleCard';
import Categories from '../components/Categories';
import Loading from '../components/Loading';

import { AppContext } from '../state';
import routes from '../config/routes';

import logoGreen from '../assets/img/logo-green.svg';
import logoNactalia from '../assets/img/logo-nactalia.png';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 1.5,
  loop:false,
  preloadImages:true,
  updateOnImagesReady:true,
  spaceBetween:16
};

const Dashboard = (props) => {

  const { state } = useContext(AppContext);
  const contentRef = useRef(null);

  function exploreCategory(){
    props.history.push( routes.search );
  }

  useIonViewDidLeave(() => {
    if(contentRef.current)
      contentRef.current.scrollToTop();
  });

  if( (state.user.loadingDetails === false && state.user.details.id) ){

    return (
      <IonPage className="cbo-dashboard">
        <IonHeader className="dashboard-header ion-padding ion-no-border">

          <IonToolbar className="header-toolbar">
            <IonTitle className="header-user" mode="md">
              Hello {state.user.details.prenom} !
            </IonTitle>
            <IonMenuButton slot="end" color="primary" />
          </IonToolbar>

        </IonHeader>
        <IonContent ref={contentRef}>

          <div className="dashboard-main">
            <div className="main-inner">
           
              <MumLevel />

              {state.user.details.currentArticle &&
              <ArticleCard 
                id={ state.user.details.currentArticle.id }
                history={ props.history }
                route={ routes.get('article', {id:state.user.details.currentArticle.id}) }
                picture={ state.user.details.currentArticle.image }
                title={ state.user.details.currentArticle.title }
                category={ state.user.details.currentArticle.category }
                actions
              />
              }

              {!state.user.details.currentArticle &&
              <IonCard className="cbo-article article--done">
                <IonCardContent className="article-content">
                  <IonImg src={ logoGreen } alt="" className="content-logo" />
                  <IonCardTitle className="content-title">
                    Bien joué !
                  </IonCardTitle>
                  <IonText className="content-text">
                    Vous avez accomplis l'ensemble des p'tits gestes ! 
                    N ‘hésitez pas à les partager autour de vous, 
                    ou à les consulter de nouveau dans la page « exploration ». 
                  </IonText>
                </IonCardContent>
              </IonCard>
              }

              {state.user.articles.length > 0 && 
              <div className="dashboard-userarticles"> 

                <IonText color="light">
                  <h2 className="cbo-thirdtitle ion-padding-start ion-padding-end ion-padding-top">Les bons gestes déjà adoptés</h2>
                </IonText>

                <IonSlides 
                  className="ion-padding" key={state.user.articles.length + 'someUniqueString'} 
                  pager={false} 
                  options={slideOpts}
                >
                  {state.user.articles && state.user.articles.map((article, index) => {

                    const rowId = 'article_'+index;

                    return( 
                      <IonSlide key={rowId}>
                        <ArticleCard 
                          id={ article.article.id }
                          history={ props.history }
                          route={ routes.get('article', {id:article.article.id}) }
                          picture={ article.article.image }
                          title={ article.article.title }
                          category={ article.article.category }
                          small
                        />
                      </IonSlide>
                    )

                  })}
                </IonSlides>
              
              </div>
              }

            </div>
          </div>

          <div className="dashboard-categories">
            <IonText color="black">
              <h2 className="cbo-secondtitle ion-padding-start ion-padding-end ion-padding-top">Nos thématiques</h2>
            </IonText>
            <Categories action={ exploreCategory } />
          </div>
          
          <div className="dashboard-footer">
            <IonImg src={ logoNactalia } alt="Nactalia" />
          </div>

        </IonContent>
      </IonPage>
    );
  }

  else{

    return(
      <IonPage>
        <IonContent>
          <Loading />
        </IonContent>
      </IonPage>
    );
  }
};

export default Dashboard;
