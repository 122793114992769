import { 
  IonContent, 
  IonPage, 
  IonFooter, 
  IonToolbar, 
  IonButton, 
  IonImg 
} from '@ionic/react';

import React from 'react';
import routes from '../config/routes';

import logo from '../assets/img/logo.png';

const Home = () => {
  return (
    <IonPage className="cbo-home">

      <IonContent className="home-content" color="primary">
        <IonImg src={logo} />
      </IonContent>

      <IonFooter className="home-footer ion-no-border ion-padding">
        <IonToolbar>
        
          <IonButton routerLink={ routes.subscribe } 
            expand="full"
            shape="round"
            size="large"
            className="cbo-button"
           >
            Je crée un compte
          </IonButton>

          <IonButton routerLink={ routes.login } 
            expand="full" 
            shape="round"
            size="large"
            className="cbo-button"
            color="secondary"
           >
            Je me connecte
          </IonButton>

        </IonToolbar>
      </IonFooter>

    </IonPage>
  );
};

export default Home;
