import { IonImg } from '@ionic/react';
import React from 'react';
import './ArticleStatus.scss';

import iconPending from '../assets/img/icon-pending.svg';
import iconValidated from '../assets/img/icon-validated.svg';

const ArticleStatus = (props) => {

	return(
		
		<div className="cbo-article-status">
		    <div className="article-status">
	        { props.status === true &&
	        <div className="status-inner status--validated">
	          <IonImg src={iconValidated} alt="" />
	          Validé
	        </div>
	        }
	        { props.status === false &&
	        <div className="status-inner status--pending">
	          <IonImg src={iconPending} alt="" />
	          Pas encore validé
	        </div>
	        }
	      </div>
	    </div>

	);
};

export default ArticleStatus;
