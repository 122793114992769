import { 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonPage, 
  IonBackButton,
  IonMenuButton,
  IonImg,
  useIonViewDidLeave
} from '@ionic/react';

import React, { useEffect, useRef, useContext } from 'react';
import { AppContext } from '../state';
import { useApi } from '../state/Actions';
import endpoints from '../config/endpoints';
import routes from '../config/routes';
import Loading from '../components/Loading';
import logoNactalia from '../assets/img/logo-nactalia.png';

const Page = (props) => {

  const { state } = useContext(AppContext);
  const { loadPage } = useApi();

  const picture = endpoints.server + state.pages.details.image;
  const style = { backgroundColor: state.pages.details.color };
  const contentRef = useRef(null);

  useEffect(() => {
    loadPage( props.match.params.id );
  }, [ props.match.params.id, loadPage ]);

  useIonViewDidLeave(() => {
    if(contentRef.current)
      contentRef.current.scrollToTop();
  });

  return (
    <IonPage className="cbo-single">

      <IonHeader className="single-header ion-no-border ion-padding">
        <IonToolbar className="header-toolbar" color="primary">
          <IonBackButton slot="start" color="light" text="" defaultHref={routes.dashboard} />
          <IonMenuButton slot="end" color="light" />
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef} className="single-inner" fullscreen>

        {(state.pages.loadingDetails === false && state.pages.details.id)
        ? <div className="single-wrap">

            <div className="single-hero" style={ style }>
              {state.pages.details.image && <IonImg src={ picture } alt="" />}
            </div>

            <h1 className="single-title ion-padding">
              {state.pages.details.title}
            </h1>

            <div className="single-content cbo-cms ion-padding" dangerouslySetInnerHTML={{__html:state.pages.details.content }}></div>

            <div className="single-footer">
              <IonImg src={ logoNactalia } alt="Nactalia" />
            </div>

          </div>

        : <Loading />
        }

      </IonContent>
    </IonPage>
  );
};

export default Page;
