import { 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonPage, 
  IonMenuButton,
  IonImg,
  IonButton,
  IonBackButton,
  useIonViewWillEnter,
  useIonViewDidLeave
} from '@ionic/react';

import React, { useContext, useRef } from 'react';
import { AppContext } from '../state';
import { useApi } from '../state/Actions';
import endpoints from '../config/endpoints';
import routes from '../config/routes';
import ArticleStatus from '../components/ArticleStatus';
import ArticleCategory from '../components/ArticleCategory';
import Loading from '../components/Loading';
import logoNactalia from '../assets/img/logo-nactalia.png';

const Article = (props) => {

  const { state } = useContext(AppContext);
  const { loadArticle, validateArticle } = useApi();
  
  const picture = endpoints.server + state.articles.details.image;
  const status = checkIfValidated( state.articles.details.id );
  const contentRef = useRef(null);

  function checkIfValidated( id ) {
    for(var i = 0; i < state.user.articles.length; i += 1) {
        if(state.user.articles[i]['article']['id'] === id && state.user.articles[i]['validated'] === true) {
            return true;
        }
    }
    return false;
  }

  useIonViewWillEnter(() => {
    loadArticle( props.match.params.id )
  });

  useIonViewDidLeave(() => {
    if(contentRef && contentRef.current)
      contentRef.current.scrollToTop();
  });
  
  return (
    <IonPage className="cbo-single">

      <IonHeader className="single-header ion-no-border ion-padding">
        <IonToolbar className="header-toolbar" color="primary">
          <IonBackButton slot="start" color="light" text="" defaultHref={routes.dashboard} />
          <IonMenuButton slot="end" color="light" />
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef} className="single-inner" fullscreen>

        {(state.articles.loadingDetails === false && state.articles.details.id)
        ? <div className="single-wrap">

            <div className="single-hero">
              <IonImg src={ picture } alt="" />
            </div>

            <div className="single-category">
              <ArticleCategory category={state.articles.details.category} />
            </div>

            <h1 className="single-title ion-padding">
              {state.articles.details.title}
            </h1>

            <div className="single-status ion-padding-start ion-padding-end">
              <ArticleStatus status={status} />
            </div>

            <div className="single-content cbo-cms ion-padding">

              {state.articles.details.chapo && state.articles.details.chapo !== '' && 
              <div className="chapo" dangerouslySetInnerHTML={{__html:state.articles.details.chapo}}></div>
              }

              {state.articles.details.content && state.articles.details.content !== '' && 
              <div className="content" dangerouslySetInnerHTML={{__html:state.articles.details.content}}></div>
              }

              {state.articles.details.push && state.articles.details.push !== '' && 
              <div className="push" dangerouslySetInnerHTML={{__html:state.articles.details.push}}></div>
              }

            </div>

            <div className="single-footer">
              { status === false && 
                <IonButton type="button"
                  expand="full" 
                  shape="round"
                  size="large"
                  className="cbo-button ion-margin"
                  color="primary"
                  onClick={() => validateArticle( state.articles.details.id, props.history.push( routes.dashboard ) )}
                 >
                  Je valide ce p’tit geste
                </IonButton>
              }
              <IonImg src={ logoNactalia } alt="Nactalia" />
            </div>
          </div>

        : <Loading />
        }

      </IonContent>

    </IonPage>
  );
};

export default Article;
