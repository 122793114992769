const loader = {

	state : {
	  	show: false
	},

	reducer : (state, action) => {
	  switch(action.type) {
	  	case "showLoader": {
	  		return { ...state, 
	  			show:true,
	  		}
	  	}
	  	case "hideLoader": {
	  		return { ...state,
	  			show:false,
	  		}
	  	}
	    default:
	    	// Do nothing
	  }
	  return state;
	}
}

export default loader