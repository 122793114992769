const user = {

	state: {  
		details: {},
		token: false,
		connected: true,
		articles: [],
		modalValidated: false,
		modalNewLevel: false,
		loadingDetails: false,
		loadingArticles: false,
		level: null
	},

	reducer : (state, action) => {
	  switch(action.type) {
	  	case "startUserDetails":{
	  		return { ...state, loadingDetails: true }
	  	}
	  	case "endUserDetails":{
	  		return { ...state, loadingDetails: false }
	  	}
	    case "setUserDetails": {
	      	return { ...state, details: action.data, loadingDetails: false }
	    }
	    case "setUserToken": {
	      	return { ...state, token: action.data }
	    }
	    case "setUserConnected": {
	      	return { ...state, connected: action.data }
	    }
	    case "startUserArticles":{
	  		return { ...state, loadingArticles: true }
	  	}
	  	case "endUserArticles":{
	  		return { ...state, loadingArticles: false }
	  	}
	    case "setUserArticles": {
	      	return { ...state, articles: action.data, loadingArticles: false }
	    }
	    case "setUserModalValidated": {
	      	return { ...state, modalValidated: action.data }
	    }
	    case "setUserModalNewLevel": {
	      	return { ...state, modalNewLevel: action.data }
	    }
	    case "setUserLevel": {
	    	return { ...state, level: action.data }
	    }
	    case "resetUser": {
	    	return {  
	    		...state,
				details: {},
				token: null,
				connected: true,
				articles: [],
				modalValidated: false,
				modalNewLevel: false,
				loadingDetails: false,
				loadingArticles: false,
				level: null
			}
	    }
	    default:
	    	// Do nothing
	  }

    return state;
  }
};

export default user