import { 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonPage, 
  IonList, 
  IonItem, 
  IonLabel, 
  IonInput, 
  IonButton,
  IonText,
  IonIcon
} from '@ionic/react';

import React, { useState, useContext } from 'react';
import { chevronBack } from 'ionicons/icons';
import axios from 'axios';
import { AppContext } from '../state';
import endpoints from '../config/endpoints';
import routes from '../config/routes';

const Password = (props) => {

  const {dispatch} = useContext(AppContext);
  const [email, setEmail] = useState('');
  const EmailCheck = (e) => {

    e.preventDefault();
    
    axios.post( endpoints.get('user', 'forgotpwd', { email: email }) )
    .then(({ data }) => {

      dispatch({type:'setToast', data:{
        show:true,
        message:'Veuillez consulter votre boite email pour recevoir votre nouveau mot de passe.',
        color:'success'
      }})

      props.history.push( routes.home );
    });
  };

  return (
    <IonPage className="cbo-page">

      <IonHeader className="page-header ion-no-border ion-padding">
         <IonToolbar className="header-toolbar" color="primary">
          <IonButtons slot="start">
            <IonButton routerLink={ routes.home } routerDirection="back">
              <IonIcon icon={ chevronBack }></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="page-content">

        <h1 className="content-title">
          Mot de passe oublié
        </h1>

        <div className="ion-padding">
          <IonText>Pour recevoir un nouveau mot de passe, veuillez saisir votre adresse email dans le champ ci-dessous.</IonText>
        </div>

        <div className="content-main ion-padding">
          <form onSubmit={e => EmailCheck(e)}>

            <IonList lines="full">

              <IonItem>
                <IonLabel position="stacked">Adresse email</IonLabel>
                <IonInput type="text" name="email" required onIonChange={e =>  setEmail(e.target.value)} />
              </IonItem>

            </IonList>

            <IonButton type="submit"
              expand="full" 
              shape="round"
              size="large"
              className="cbo-button ion-margin"
              color="primary"
             >
              Recevoir mon mot de passe
            </IonButton>

          </form>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Password;
