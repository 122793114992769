const articles = {

	state : {
	  	show: false,
	  	message: '',
	  	color: ''
	},

	reducer : (state, action) => {
	  switch(action.type) {
	  	case "setToast": {
	  		return { ...state, 
	  			show: action.data.show, 
	  			message: action.data.message, 
	  			color: action.data.color 
	  		}
	  	}
	  	case "resetToast": {
	  		return { ...state,
	  			show:false,
	  			message:'',
	  			color:''
	  		}
	  	}
	    default:
	    	// Do nothing
	  }
	  return state;
	}
}

export default articles