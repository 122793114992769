const explore = {

	state : {
	  	category: null,
	  	hasBaby: null,
	},

	reducer : (state, action) => {
	  switch(action.type) {
	    case "setExploreCategory": {
	      	return { ...state, category:action.data }
	    }
	    case "setExploreHasBaby": {
	      	return { ...state, hasBaby:action.data }
	    }
	    case "resetExplore": {
	    	return { ...state, category:null, hasBaby:null }
	    }
	    default:
	    	// Do nothing
	  }
	  return state;
	}
}

export default explore