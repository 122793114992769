import React, { createContext, useReducer } from "react";
import combineReducers from 'react-combine-reducers';
import user from './user.js';
import pages from './pages.js';
import articles from './articles.js';
import categories from './categories.js';
import explore from './explore.js';
import popins from './popins.js';
import toast from './toast.js';
import loader from './loader.js';

let AppContext = createContext();

function AppContextProvider(props) {

  const [rootReducerCombined, initialStateCombined] = combineReducers({
      user: [user.reducer, user.state],
      pages: [pages.reducer, pages.state],
      articles: [articles.reducer, articles.state],
      categories: [categories.reducer, categories.state],
      explore: [explore.reducer, explore.state],
      toast: [toast.reducer, toast.state],
      loader: [loader.reducer, loader.state],
      popins: [popins.reducer, popins.state]
  });

  let [state, dispatch] = useReducer(rootReducerCombined, initialStateCombined);
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };