const pages = {

	state : {
	  	list: [],
	  	details: {},
	  	loadingList: false,
	  	loadingDetails: false
	},

	reducer : (state, action) => {
	  switch(action.type) {
	  	case "startPages": {
	      	return { ...state, loadingList:true }
	    }
	    case "endPages": {
	      	return { ...state, loadingList:false }
	    }
	    case "setPages": {
	      	return { ...state, list:action.data, loadingList:false }
	    }
	    case "startPage": {
	      	return { ...state, loadingDetails:true }
	    }
	    case "endPage": {
	      	return { ...state, loadingDetails:false }
	    }
	    case "setPage": {
	      	return { ...state, details:action.data, loadingDetails:false }
	    }
	    case "resetPages": {
	    	return { ...state, list:[], details:{}, loadingList:false, loadingDetails:false }
	    }
	    default:
	    	// Do nothing
	  }
	  return state;
	}
}

export default pages