import { IonText } from '@ionic/react';
import React from 'react';
import './ArticleCategory.scss';

const ArticleCategory = (props) => {

	function string_to_slug(str)
	{
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
		var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

		for (var i=0, l=from.length ; i<l ; i++)
		{
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str.replace('.', '-') // replace a dot by a dash 
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by a dash
			.replace(/-+/g, '-') // collapse dashes
			.replace( /\//g, '' ); // collapse all forward-slashes

		return str;
	}

	let name = '';
	let slug = '';

	if(props.category){
	 	name = props.category.name || '';
	 	slug = props.category.name ? string_to_slug( props.category.name ) : 'undefined';
	}

	return(

	    <IonText className={`cbo-article-category ${ name ? "cbo-bg--"+slug : ""}`}>
	    	{name}
		</IonText>

	);
};

export default ArticleCategory;
