import { 
  IonContent, 
  IonPage, 
  IonFooter, 
  IonText,
  IonButton,
  IonImg
} from '@ionic/react';

import React from 'react';
import { useApi } from '../state/Actions';

import logo from '../assets/img/logo.png';

const NotConnected = ( props ) => {

  const { loadUser } = useApi();

  return (
    <IonPage className="cbo-home">

      <IonContent className="home-content" color="primary">
        <IonImg src={logo} />
      </IonContent>

      <IonFooter className="home-footer ion-no-border ion-padding">
        <IonText className="footer-connection" color="primary">
          <h1>Vérifiez votre connexion (Wifi, données mobiles)</h1>
        </IonText>
        <IonButton onClick={() => loadUser()}
          expand="full"
          shape="round"
          size="large"
          className="cbo-button"
         >
          Réessayer
        </IonButton>
        
      </IonFooter>

    </IonPage>
  );
};

export default NotConnected;
