import { 
	IonSlides, 
	IonSlide,
	IonImg
} from '@ionic/react';

import React, { useContext, useEffect, useState }from 'react';
import { AppContext } from '../state';
import endpoints from '../config/endpoints';

import iconAll from '../assets/img/icon-all-categories.svg';
import './Categories.scss';

const Categories = ( props ) => {

	const { state, dispatch } = useContext(AppContext);
	const [ swiper, setSwiper ] = useState({});

	const initSlider = async function(){
		setSwiper( await this.getSwiper() );
	}

	function action( id ){
		dispatch({type:'setExploreCategory', data:id});
		if(props.action)
			props.action()
	}

	function string_to_slug(str){
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
		var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

		for (var i=0, l=from.length ; i<l ; i++)
		{
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str.replace('.', '-') // replace a dot by a dash 
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by a dash
			.replace(/-+/g, '-') // collapse dashes
			.replace( /\//g, '' ); // collapse all forward-slashes

		return str;
	}

	useEffect(() => {
		if(props.showCurrent && state.explore.category !== null){
			for(const i_cat in state.categories.list){
				if(state.explore.category === state.categories.list[i_cat].id && swiper.slideTo){
					swiper.slideTo( i_cat )
				}
			}
		}
	}, [props.showCurrent, state.explore.category, state.categories.list, swiper]);

	const currentAll = (state.explore.category === null) ? 'slide--current' : '';

	if( state.categories.loadingList === false && state.categories.list.length > 0 ){

		const slideOpts = {
			initialSlide: 0,
			speed: 400,
			slidesPerView: 1,
			loop:false,
			preloadImages:true,
			updateOnImagesReady:true
		};

	  	return(

	  		<div className={`cbo-categories ${ props.showCurrent ? "categories--currentMode" : ""}`}>
		  		<IonSlides className="categories-slider" pager={false} options={slideOpts} onIonSlidesDidLoad={initSlider}>
		  			
		  			<IonSlide className={`slider-slide ${ currentAll }`}>
		  				<div className="slide-inner" onClick={() => action( null ) }>
		  					<div className="slide-background"></div>
		  					<div className="slide-picture">
		  						<IonImg src={ iconAll } alt="" />
		  					</div>
		  					<div className="slide-title">
		  						Toutes
		  					</div>
			  			</div>
			  		</IonSlide>

				{state.categories.list && state.categories.list.map((category, index) => {

					const key = 'category_'+index;
					const picture = endpoints.server+category.logo;
					const slug = string_to_slug( category.name );

					let current = '';
					if( state.explore.category !== null )
						current = state.explore.category === category.id ? 'slide--current':'';

					return(
		  			<IonSlide key={key} className={`slider-slide ${ current }`}>
		  				<div className="slide-inner" onClick={() => action( category.id ) }>
		  					<div className={`slide-background ${ category.name ? "cbo-bg--"+slug : ""}`}></div>
		  					<div className="slide-picture">
		  						<IonImg src={ picture } alt="" />
		  					</div>
		  					<div className="slide-title">
		  						{ category.name }
		  					</div>
			  			</div>
		  			</IonSlide>
		  			);
		  		})}

		  		</IonSlides>
	  		</div>

	  	);
	}

	else{
		return(
			<div></div>
		);
	}
};

export default Categories;
