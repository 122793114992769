const articles = {

	state : {
	  	list: [],
	  	details: {},
	  	loadingList: false,
	  	loadingDetails: false
	},

	reducer : (state, action) => {
	  switch(action.type) {
	  	case "startArticles": {
	  		return {...state, list:[], loadingList:true }
	  	}
	  	case "endArticles": {
	  		return {...state, loadingList:false }
	  	}
	    case "setArticles": {
	      	return { ...state, list:action.data, loadingList:false }
	    }
	    case "startArticle": {
	  		return {...state, details:{}, loadingDetails:true }
	  	}
	  	case "endArticle": {
	  		return {...state, loadingDetails:false }
	  	}
	    case "setArticle": {
	      	return { ...state, details:action.data, loadingDetails:false }
	    }
	    case "resetArticles": {
	    	return { ...state, list:[], details:{}, loadingList:false, loadingDetails:false }
	    }
	    default:
	    	// Do nothing
	  }
	  return state;
	}
}

export default articles