import { useContext, useCallback } from 'react';
import axios from 'axios';
import { AppContext } from '../state';
import { deleteToken } from '../config/storage';
import endpoints from '../config/endpoints';

export function useApi(){

	const { state, dispatch } = useContext(AppContext);

  const login = ( token, userLevel ) => {
    axios.defaults.headers.common['Authorization'] = token;

    // Test connection

    loadUser(
      null, 
      () => {dispatch({type:'setUserToken', data:token})}
    );
  }

  const logout = ( callback ) => {
    deleteToken().then(() => {
      delete axios.defaults.headers.common['Authorization'];
      dispatch({type:'resetUser'});
      dispatch({type:'resetArticles'});
      dispatch({type:'resetCategories'});
      dispatch({type:'resetExplore'});

      // Callback

      if(callback)
        callback();
    })
  }

  const loadUser = useCallback( ( userLevel, callback, fallback ) => {
    axios.get( endpoints.user.getDetails )
    .then( (response) => {
      
      // Has baby

      let hasBaby = false;
      if(response.data.dateNaissanceBebe){
        const d1 = new Date();
        const d2 = new Date(response.data.dateNaissanceBebe);
        hasBaby = d1.getTime() > d2.getTime() ? true : false;
      }

      // Store details & Explore hasBaby option

      dispatch({type:'setUserDetails', data:response.data});
      dispatch({type:'setExploreHasBaby', data:hasBaby});

      // New level modal

      if(userLevel !== null && userLevel < response.data.statutNote){
        dispatch({type:'setUserModalNewLevel',data:true});
      }

      // User level update

      dispatch({type:'setUserLevel',data:response.data.statutNote});
      
      // Callback

      if(callback)
        callback();
    })
    .catch( (error) => {
      if(fallback && error.response)
        fallback();
    })
  }, [dispatch]);

  const loadPage = useCallback(( id ) => {
    dispatch({type:'startPage'});
    axios.get( endpoints.get('pages', 'getDetails', {id:id} ))
    .then( (response) => {
      dispatch({type:'setPage', data:response.data});
    })
    .catch( () => {
      dispatch({type:'endPage'});
    })
  }, [dispatch]);

  const loadPages = useCallback(() => {
    axios.get( endpoints.pages.getList)
    .then( (response) => {
      dispatch({type:'setPages', data:response.data});
    })
  }, [dispatch]);

  const loadArticle = useCallback(( id, callback ) => {
    dispatch({type:'startArticle'});
    axios.get( endpoints.get('articles', 'getDetails', {id:id} ))
    .then( (response) => {
      dispatch({type:'setArticle', data:response.data});

      if(callback)
        callback();
    })
    .catch( () => {
      dispatch({type:'endArticle'});
    })
  }, [dispatch]);

  const loadArticles = useCallback(( callback ) => {
    dispatch({type:'startArticles'});
    axios.get( endpoints.articles.getList)
    .then( (response) => {
      dispatch({type:'setArticles', data:response.data});
      if(callback)
        callback();
    })
    .catch( () => {
      dispatch({type:'endArticles'});
      if(callback)
        callback();
    })
  }, [dispatch]);
  
  const loadUserArticlesList = useCallback(() => {
    dispatch({type:'startUserArticles'});
    axios.get( endpoints.userArticles.getList, { page:1 } )
    .then( (response) => {
      dispatch({type:'setUserArticles', data:response.data});
    })
    .catch( () => {
      dispatch({type:'endUserArticles'});
    })
  }, [dispatch]);

  const validateArticle = ( id, callback ) => {
    axios.post( endpoints.get( 'articles', 'validate', {id:id} ) )
    .then( (response) => {
      
      loadUser( state.user.level );
      loadUserArticlesList();
      loadPopins();

      dispatch({ type:'setUserModalValidated', data:true });
      if(callback)
        callback();
    })
  }

  const ignoreArticle = ( id ) => {
    axios.post( endpoints.get( 'articles', 'ignore', {id:id} ) )
    .then( (response) => {
      loadUser( state.user.level );
      loadUserArticlesList();
    })
  };

  const loadCategories = useCallback(() => {
    dispatch({type:'startCategories'});
    axios.get( endpoints.categories.getList, )
    .then( (response) => {
      dispatch({type:'setCategories', data:response.data});
    })
    .catch( () => {
      dispatch({type:'endCategories'});
    })
  }, [dispatch]);

  const loadPopins = useCallback(( callback ) => {
    dispatch({type:'startPopins'});
    axios.get( endpoints.popins.getList)
    .then( (response) => {
      dispatch({type:'setPopins', data:response.data});

      for( let i = 0; i < response.data.length; i++ ){
        if( state.user.level === response.data[i].note && response.data[i].enabled === true ){
          dispatch({ type:'setPopinSelected', data:response.data[i] });
        }
      }

      if(callback)
        callback();
    })
    .catch( () => {
      dispatch({type:'endPopins'});
      if(callback)
        callback();
    })
  }, [dispatch, state.user.level]);

  const loadData = useCallback(() => {
    loadUserArticlesList();
    loadArticles();
    loadCategories();
    loadPages();
    loadPopins();
  }, [loadUserArticlesList, loadArticles, loadCategories, loadPages, loadPopins]);

  return { 
    login,
    logout,
    loadUser, 
    loadPage,
    loadPages,
    loadArticle, 
    loadArticles, 
    loadUserArticlesList, 
    ignoreArticle, 
    validateArticle, 
    loadCategories,
    loadPopins,
    loadData
  };
}