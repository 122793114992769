const categories = {

	state : {
	  	list: [],
	  	details: {},
	  	loadingList: false
	},

	reducer : (state, action) => {
	  switch(action.type) {
	    case "startCategories": {
	      	return { ...state, loadingList:true }
	    }
	    case "endCategories": {
	      	return { ...state, loadingList:false }
	    }
	    case "setCategories": {
	      	return { ...state, list:action.data, loadingList:false }
	    }
	    case "setCategory": {
	      	return { ...state, details:action.data }
	    }
	    case "resetCategories": {
	    	return { ...state, list:[], details:{}, loadingList:false }
	    }
	    default:
	    	// Do nothing
	  }
	  return state;
	}
}

export default categories