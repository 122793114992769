import { 
  IonContent, 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonSearchbar, 
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonMenuButton,
  IonText,
  IonRefresher, 
  IonRefresherContent,
  useIonViewDidLeave
} from '@ionic/react';

import React, { useContext, useState, useRef, useEffect }  from 'react';
import { AppContext } from '../state';
import { useApi } from '../state/Actions';
import routes from '../config/routes';
import ArticleCard from '../components/ArticleCard';
import Categories from '../components/Categories';
import Loading from '../components/Loading';

const Search = (props) => {

  const { state, dispatch } = useContext(AppContext);
  const [ searchText, setSearchText ] = useState('');
  const [ filteredArticles, setFilteredArticles ] = useState([]);
  const contentRef = useRef(null);
  const { loadArticles } = useApi();

  function setHasBaby( value ){
    value = ( value === 'true' ) ? true : value;
    value = ( value === 'false' ) ? false : value;
    dispatch({ type:'setExploreHasBaby', data:value });
  }

  function checkIfValidated( id ) {
    for(var i = 0; i < state.user.articles.length; i += 1) {
        if(state.user.articles[i]['article']['id'] === id && state.user.articles[i]['validated'] === true) {
            return true;
        }
    }
    return false;
  }

  function redirectToDashboard(){
    props.history.push( routes.dashboard )
  }

  function refreshArticles(e){
    loadArticles( () => e.detail.complete() );
  }

  function onKey(e){
      if (e.keyCode === 13) {
          let activeElement = document.activeElement;
          activeElement && activeElement.blur && activeElement.blur();
      }
  }

  /* eslint-disable */
  useEffect(() => {
    let selectedArticles = [];
    for( const i_art in state.articles.list ){

       const myArticle = state.articles.list[i_art];
       const lowerQuery = searchText.toLowerCase();
       const lowerTitle = myArticle.title.toLowerCase();

       if(( lowerTitle.indexOf( lowerQuery ) !== -1 ) && 
          ( state.explore.category === null || state.explore.category === myArticle.category.id ) &&
          ( state.explore.hasBaby === null || state.explore.hasBaby === myArticle.hasBebe )){

         selectedArticles.push( myArticle )
       }
    }
    setFilteredArticles( selectedArticles )

  }, [state.articles.list, state.explore.category, state.explore.hasBaby, searchText])
  /* eslint-enable */

  useIonViewDidLeave(() => {
    if(contentRef.current)
      contentRef.current.scrollToTop();
  });

  return (
    <IonPage className="cbo-search">
      <IonHeader className="search-header ion-padding ion-no-border">
        <IonToolbar className="header-toolbar">
          <IonMenuButton slot="end" color="white" />
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} fullscreen className="search-inner">

        <IonRefresher className="cbo-refresher" slot="fixed" onIonRefresh={(e) => refreshArticles(e)}>
          <IonRefresherContent className="refresher-button"></IonRefresherContent>
        </IonRefresher>
       
        <div className="search-title ion-padding">
          <h1 className="cbo-secondtitle">Explorez</h1>
        </div>

        <div className="search-filters ion-padding">

          <IonSearchbar 
            placeholder="Votre recherche"
            className="filters-keywords"
            value={searchText} 
            onIonChange={e => setSearchText(e.target.value)}
            onKeyDown={e => onKey(e)}
          ></IonSearchbar>

          <div className="filters-hasbaby">
            <IonSegment value={ state.explore.hasBaby } onIonChange={e => setHasBaby( e.detail.value )}>
              <IonSegmentButton value={ null }>
                <IonLabel class="button-label">Tous</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={ false }>
                <IonLabel class="button-label">Je suis enceinte</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={ true }>
                <IonLabel class="button-label">Bébé est né</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>

          <div className="filters-categories">
            <Categories showCurrent />
          </div>

          <div className="filters-count">
          {filteredArticles.length > 0 &&
            <span>{ filteredArticles.length } résultat{filteredArticles.length > 1 && 's' } </span>
          }
          {filteredArticles.length === 0 &&
            <span>&nbsp;</span>
          }
          </div>

        </div>

        {state.articles.loadingList === false && state.user.loadingArticles === false && state.articles.list.length > 0 &&
        <div className="search-main">

          {filteredArticles.length > 0
          ? <div className="main-content">

              {filteredArticles.map((article, index) => {

                const rowId = 'article_'+index;

                return( 
                  <ArticleCard 
                    key={rowId}
                    id={ article.id }
                    history={ props.history }
                    route={ routes.get('article', {id:article.id}) }
                    picture={ article.image }
                    title={ article.title }
                    category={ article.category }
                    status={ checkIfValidated( article.id ) }
                    validateCallback={ redirectToDashboard }
                    actions
                  />
                )
              })}

            </div>
         
          : <div className="main-content">
              <IonText className="content-result">
                Aucun résultat
              </IonText>
            </div>
          }

        </div>
        }

        {(state.articles.loadingList === true || state.user.loadingArticles === true) &&
        <div className="search-main">
          <div className="main-content">
            <Loading />
          </div>
        </div>
        }

      </IonContent>
    </IonPage>
  );
};

export default Search;
