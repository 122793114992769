import { IonImg } from '@ionic/react';
import React, { useContext } from 'react';
import { AppContext } from '../state';
import './MumLevel.scss';

import StarActive from '../assets/img/star-active.svg';
import StarInactive from '../assets/img/star-inactive.svg';

const MumLevel = (props) => {

	const { state } = useContext(AppContext);

	const max = 6;

	function activeStars(){
		let stars = [];
		for(let i = 0; i < state.user.level; i++){
			const key = 'star_'+i;
			stars.push(<IonImg className="stars-el" key={key} src={StarActive} />)
		}
		return stars;
	}

	function inactiveStars(){
		let stars = [];
		for(let i = state.user.level; i < max; i++){
			const key = 'star_'+i;
			stars.push(<IonImg className="stars-el" key={key} src={StarInactive} />)
		}
		return stars;
	}

	if( (state.user.loadingDetails === false && state.user.level !== null) ){

		return(

		    <div className={`cbo-mumlevel ${ props.reverseColors ? "mumlevel--reverseColors" : ""}`}>
		    	<div className="mumlevel-stars">
			    	{activeStars()}
			    	{inactiveStars()}
			    </div>
			    <div className="mumlevel-label">{state.user.details.labelNote}</div>
			</div>

		);

	} else {

		return(
			<div></div>
		);
	}
};

export default MumLevel;
