import { 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonPage, 
  IonButton,
  IonMenuButton,
  useIonViewDidLeave
} from '@ionic/react';

import React, {useContext, useRef} from 'react';
import routes from '../config/routes';
import UserForm from '../components/UserForm';
import Loading from '../components/Loading';

import { AppContext } from '../state';
import { useApi } from '../state/Actions';

const Settings = (props) => {

  const { state } = useContext(AppContext);
  const { logout } = useApi();
  const contentRef = useRef(null);

  useIonViewDidLeave(() => {
    if(contentRef.current)
      contentRef.current.scrollToTop();
  });

  return (
    <IonPage className="cbo-page">

      <IonHeader className="page-header ion-no-border ion-padding">
         <IonToolbar className="header-toolbar" color="primary">
          <IonMenuButton slot="end" color="light" />
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef} className="page-content">

        {state.user.loadingDetails === false && state.user.details.email
        ? <div className="content-inner">

            <h1 className="content-title">
               Mon profil
            </h1>

            <div className="content-main">
              <UserForm mode="UPDATE" />
              <IonButton type="button"
                expand="full" 
                shape="round"
                size="large"
                className="cbo-button ion-margin"
                color="secondary"
                onClick={() => logout(() => props.history.push( routes.home ))}
               >
                Déconnexion
              </IonButton>
            </div>

          </div>

        : <div className="content-inner">
            <Loading />
          </div>
        }

      </IonContent>
    </IonPage>
  );
};

export default Settings;

