const routes = {

	'home' : '/home',
	'login' : '/login',
	'password' : '/password',
	'subscribe' : '/subscribe',

	'logged' : '/logged',
	'not_connected' : '/oops',

	'dashboard' : '/logged/aujourdhui',
	'page' : '/logged/page/:id',
	'search' : '/logged/explorer',
	'article' : '/logged/article/:id',
	'settings' : '/logged/profil',

	get( route, params ){
		let r = this[ route ]
	    for (let [key, value] of Object.entries( params )) {
	      r = r.replace( ':'+key, value )
	    }
	    return r
	}
};

export default routes;