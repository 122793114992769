import { 
  IonCard,  
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonIcon,
  IonActionSheet,
  IonImg
} from '@ionic/react';

import React, { useState } from 'react';
import { useApi } from '../state/Actions';
import endpoints from '../config/endpoints';
import { ellipsisHorizontal } from 'ionicons/icons';
import ArticleStatus from './ArticleStatus';
import ArticleCategory from './ArticleCategory';
import './ArticleCard.scss';

const ArticleCard = ( props ) => {
  
  const [showActionSheet, setShowActionSheet] = useState(false);
  const {validateArticle, ignoreArticle} = useApi();
  const picture = props.picture ? endpoints.server + props.picture : null;
  const callback = props.validateCallback ? props.validateCallback : null;

  function goToArticle(){
    props.history.push( props.route );
  }

	return(

		<IonCard
      className={`cbo-article ${ props.small ? "article--small" : ""} ${ props.hidden ? "article--hidden" : ""}`}
    >

      <IonCardHeader className="article-picture" onClick={() => goToArticle()}>
        {picture && <IonImg src={ picture } alt="" /> }
      </IonCardHeader>
      <IonCardContent className="article-content" onClick={() => goToArticle()}>        
        <IonCardSubtitle className="content-category">
          <ArticleCategory category={ props.category } />
        </IonCardSubtitle>
        <IonCardTitle className="content-title">
          { props.title }
        </IonCardTitle>
      </IonCardContent>

      { !props.small &&
      <div className="article-status">
        <ArticleStatus status={ props.status } />
      </div>
      }

      { (props.actions === true && !props.status) &&
      <div className="article-actions">
        <IonButton className="actions-button" onClick={() => setShowActionSheet(true)} fill="clear">
          <IonIcon icon={ ellipsisHorizontal }></IonIcon>
        </IonButton>
      </div>
      }

      { (props.actions === true && !props.status) &&
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[{
          text: 'Je l\'ai déjà adopté',
          handler: () => {
            validateArticle( props.id, callback )
          }
        }, {
          text: 'On verra plus tard',
          role: 'destructive',
          handler: () => {
            ignoreArticle( props.id )
          }
        }]}
      >
      </IonActionSheet>
      }

    </IonCard>

	);
};

export default ArticleCard;
